import './AboutSection.scss';

import slickControl from 'Components/SlickControl/SlickControl';
import $ from 'jquery';
import slick from 'Libs/slick';

export default {

	init() {

		var container = document.querySelector('section.AboutSection');

		if( container ) {

			// -- slick carousel -- //

				function aboutSectionCarousel() {

					var carousel = container.querySelector('.aboutSection__slider .slider__carousel');
					

					$(carousel).slick({
						slidesToShow: 1,
						slidesToSlide: 1,
						infinite: true,
						arrows: false,
						dots: true,
						autoplay: true,
						autoplaySpeed: 2000,
					});

					container.querySelector('.SlickControl__pause').addEventListener('click', function() {
						slickControl.pause( container, $(carousel) );
					});

					container.querySelector('.SlickControl__resume').addEventListener('click', function() {
						slickControl.resume( container, $(carousel) );
					});

				}
				aboutSectionCarousel();

			// -- END -- //

		}

	}

}