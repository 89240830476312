import './Cookie.scss';

export default {
	init() {

		var t = this;
		var body = document.querySelector('body');

		function OnCookieContainerClick() {

			var cookieContainer = document.querySelector('.cookie');
			var cookiePrivacyPolicy = cookieContainer.querySelector('a');

			cookieContainer.querySelector('.row__accept').addEventListener('click', function() {

				t.SetCookie(cookieName , cookieValue , cookieLive);
				cookieContainer.classList.remove('active');
				cookieContainer.parentNode.removeChild(cookieContainer);
				
			});
			cookieContainer.querySelector('.row__close').addEventListener('click', function() {

				t.SetCookie(cookieName , cookieValue , 1);
				cookieContainer.classList.remove('active');
				cookieContainer.parentNode.removeChild(cookieContainer);
				
			});

			cookiePrivacyPolicy.addEventListener('click', function(e){

				e.stopPropagation();

			});

		}

		function ShowCookieDiv() {
			var url = document.querySelector('footer').getAttribute('data-endpoint');

			var req = new XMLHttpRequest();

			req.onreadystatechange = function(){

				if ( req.readyState==4 && req.status==200 ) {
					
					document.querySelector('body').insertAdjacentHTML('beforeend', req.responseText);				

					setTimeout(
						function(){ 
							document.querySelector('.cookie').classList.add('active');
						}, 
						1000
					);

					
					OnCookieContainerClick();

				}

			}

			req.open( 'POST', url, true );
			req.setRequestHeader( 'Content-type', 'application/x-www-form-urlencoded' );
			req.send( 'action=CookieInfo' );
			
		}	

		var cookieName = 'cookie-info';
		var cookieValue = '1';
		var cookieLive = 7; //in day
		//showCookieDiv() remember to change url do php file (line 2)

		var myCookie = t.CheckCookie(cookieName);
		
		if(myCookie != 1) {
			ShowCookieDiv();
		};

		

	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
	SetCookie(cname, cvalue, exdays) {
	    var d = new Date();
	    d.setTime(d.getTime() + (exdays*24*60*60*1000));
	    var expires = "expires="+ d.toUTCString();
	    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	},
	CheckCookie(cname) {

	    var name = cname + "=";
	    var decodedCookie = decodeURIComponent(document.cookie);
	    var ca = decodedCookie.split(';');
	    for(var i = 0; i <ca.length; i++) {
	        var c = ca[i];
	        while (c.charAt(0) == ' ') {
	            c = c.substring(1);
	        }
	        if (c.indexOf(name) == 0) {
	            return c.substring(name.length, c.length);
	        }
	    }
	    return "";

	}
};
