import './SlickControl.scss';

export default {
	pause( container, carousel ) {
		carousel.slick('slickPause');
		container.querySelector('.SlickControl .SlickControl__pause').classList.add('hide');
		container.querySelector('.SlickControl .SlickControl__resume').classList.remove('hide');
	},
	resume( container, carousel ) {
		carousel.slick('slickPlay');
		container.querySelector('.SlickControl .SlickControl__pause').classList.remove('hide');
		container.querySelector('.SlickControl .SlickControl__resume').classList.add('hide');
	}
}