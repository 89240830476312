import Cookie from 'Components/Cookie/Cookie';
import Button from 'Components/Button/Button';

export default {

	init() {

		Cookie.init();

		function headerSubMenu() {
			var buttons = document.querySelectorAll('header button.menu__item');
			buttons.forEach( function( button, index ) {
				button.addEventListener('click', function(e) {
					e.stopPropagation();
					if( button.closest('.header__item').classList.contains('active') ) {
						button.closest('.header__item').classList.remove('active');
					} else {
						button.closest('.header__item').classList.add('active');				
					}
					buttons.forEach( function( button2, index2 ) {
						if( index2 != index ) {
							button2.closest('.header__item').classList.remove('active');
						}
					});
				});
			});
			document.addEventListener('click', function() {
				buttons.forEach( function( button, index ) {
					button.closest('.header__item').classList.remove('active');
				});
			});

		}
		headerSubMenu();


		function subpageMobileMenu() {
			var body = document.querySelector('body');

			var mobileMenu = document.querySelector('section.MobileMenu');
			var mobileMenuContent = mobileMenu.querySelector('.mobileMenu__content');

			var hamburger = document.querySelector('header .left__hamburger');

			hamburger.addEventListener('click', function() {

				if( hamburger.querySelector('.hamburger').classList.contains('is-active') ) {
					mobileMenu.classList.remove('active');
					body.classList.remove('disableScroll');
					hamburger.querySelector('.hamburger').classList.remove('is-active');
				} else {
					mobileMenu.classList.add('active');
					body.classList.add('disableScroll');
					hamburger.querySelector('.hamburger').classList.add('is-active');
				}

			});

			mobileMenu.addEventListener('click', function() {

				mobileMenu.classList.remove('active');
				body.classList.remove('disableScroll');
				hamburger.querySelector('.hamburger').classList.remove('is-active');

			});

			mobileMenuContent.addEventListener('click', function(e) {

				e.stopPropagation();

			});
		}
		subpageMobileMenu();

		// -- contrast -- //
			var buttonContrast = document.querySelector('button.control__contrast');
			buttonContrast.addEventListener('click', function() {

				if( document.body.classList.contains('highContrast') ) {
					document.body.classList.remove('highContrast');
					Cookie.SetCookie('high-contrast','0',1);
				} else {
					document.body.classList.add('highContrast');
					Cookie.SetCookie('high-contrast','1',1);
				}

			});
		// -- END -- //


		 // -- control font-size -- //
            var buttons = document.querySelectorAll('.control__size button');
            buttons.forEach( function( button, index ) {
                button.addEventListener('click', function() {

                    document.body.classList.remove('size-1');
                    document.body.classList.remove('size-2');
                    document.body.classList.remove('size-3');
                    document.body.classList.add(button.getAttribute('data-class'));
                    Cookie.SetCookie('font-size',button.getAttribute('data-class'),1);

                });
            });
        // -- END -- //


         // -- check cookies -- //
            if( Cookie.CheckCookie('font-size') ) {
                document.body.classList.remove('size-1');
                document.body.classList.remove('size-2');
                document.body.classList.remove('size-3');
                document.body.classList.add( Cookie.CheckCookie('font-size') );
            }
            if( Cookie.CheckCookie('high-contrast') == '1' ) {
                document.body.classList.add('highContrast');
            } else {
                document.body.classList.remove('highContrast');
            }
        // -- END -- //

	},	
	finalize() {

	}

}