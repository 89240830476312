import './Homepage.scss';
import 'Sections/AdditionalInformation/AdditionalInformation';
import AboutSection from 'Sections/AboutSection/AboutSection';
import NewsSection from 'Sections/NewsSection/NewsSection';
import GroupSection from 'Sections/GroupSection/GroupSection';
import Button from 'Components/Button/Button';


document.addEventListener("DOMContentLoaded", function() {

	AboutSection.init();

});